/* Common CSS Start */
@media (min-width: 992px) {
  html {
    font-size: 75%;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 68%;
  }
}
@media (min-width: 1300px) {
  html {
    font-size: 74%;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 76%;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 90%;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 100%;
  }
}
@media (max-width: 320px) {
  html {
    font-size: 80%;
  }
}
html,
body {
  height: 100%;
}
.title-color {
  color: #292d32;
}
.btn-lg.ku-btn {
  font-weight: 700 !important;
  font-family: "Nunito Bold";
}

.customScroll::-webkit-scrollbar {
  width: 0.375rem;
  background-color: #d9d9d9;
  border-radius: 1rem;
}

.customScroll::-webkit-scrollbar-track {
  border-radius: 1rem;
  background-color: #d9d9d9;
}
.customScroll::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #707070;
}
/* Common CSS End */

/* Login Pages CSS Stat */
.login-container {
  background-color: #e1fcdb;
  min-height: 100vh;
}
.login-container::after {
  content: "";
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: 48.313rem;
  height: 48.313rem;
  max-width: 100%;
  background: #ee853a;
  opacity: 0.56;
  filter: blur(195px);
}
.login_v_1 {
  width: 21.75rem;
  height: 21.688rem;
  right: -8rem;
  top: -10rem;
}
.login_v_2 {
  width: 36.75rem;
  height: 36.875rem;
  left: -15rem;
  bottom: -13rem;
}
.login_v_3 {
  width: 69.563rem;
  height: 69.563rem;
  right: -37rem;
  bottom: -37rem;
}
.login-logo {
  height: 6.25rem;
  object-fit: contain;
}
.login_form {
  width: 27.5rem;
  max-width: 100%;
}
.password_show_hide {
  top: 0;
  right: 1rem;
  height: 100%;
  line-height: 2.75rem;
  width: 1.25rem;
  text-align: center;
}
.password_show_hide svg {
  width: 1.125rem;
  height: 1.125rem;
}
.reset_icon {
  height: 3.75rem;
}
@media (min-width: 576px) {
  .login_form .card > .card-body {
    padding: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .login_v_1 {
    width: 18.75rem;
    height: 18.688rem;
  }
  .login_v_2 {
    width: 28.75rem;
    height: 28.875rem;
  }
  .login_v_3 {
    width: 56.563rem;
    height: 56.563rem;
  }
}
@media (max-width: 575.98px) {
  .login_v_1 {
    width: 15.75rem;
    height: 15.688rem;
  }
  .login_v_2 {
    width: 22.75rem;
    height: 22.875rem;
  }
  .login_v_3 {
    width: 51rem;
    height: 51rem;
  }
  .login-logo {
    height: 4.25rem;
  }
  .login_form .card > .card-body {
    padding: 2rem 1rem;
  }
}

/* Login Pages CSS End */

/******* Main Pages CSS Start *******/

/* Sidebar Nav Menu Start */

.sidenav_nav {
  width: 15rem;
  max-width: 100%;
  height: 100vh;
  /* z-index: 1038; */

  flex-basis: 20rem;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 999;
  transform: translateX(-20rem);
  -webkit-transform: translateX(-20rem);
  -moz-transform: translateX(-20rem);
  -ms-transform: translateX(-20rem);
  -o-transform: translateX(-20rem);
}

.sidenav_content {
  padding-left: calc(14.5rem + 2.5rem);
  min-width: 0;
  flex-grow: 1;
  min-height: 100vh;
  margin-left: -17rem;
  position: relative;
  top: 6.75rem;
}
.main-wrapper {
  position: relative;
}
.main-wrapper::after {
  content: "";
  position: fixed;
  background: #ee853a;
  opacity: 0.56;
  filter: blur(195px);
  width: 37.438rem;
  height: 37.438rem;
  z-index: -1;
  bottom: -15rem;
  right: -15rem;
}
@media (min-width: 992px) {
  .sidenav_nav {
    transform: translateX(0);
    margin: 1.25rem;
    height: calc(100vh - 2.5rem);
  }
  .sidenav_content {
    margin-left: 0;
    transition: margin 0.25s ease-in-out;
    top: 0;
    -webkit-transition: margin 0.25s ease-in-out;
    -moz-transition: margin 0.25s ease-in-out;
    -ms-transition: margin 0.25s ease-in-out;
    -o-transition: margin 0.25s ease-in-out;
  }
}

@media (max-width: 991.98px) {
  .sidenav_nav.open_sidebar {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}
@media (max-width: 575.98px) {
  .sidenav_content .container-fluid.px-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
.logo_bg {
  background-color: #e1fcdb;
  padding: 1.25rem;
}
.logo_bg img {
  height: 2.25rem;
  object-fit: contain;
}
.sidebar_link {
  padding: 0.5rem 1.563rem;
  color: #292d32;
}
.sidebar_link::before {
  content: "";
  height: 2.5rem;
  width: 0.313rem;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: transparent;
  transform: translate(-50%, -50%);
  border-radius: 0 0.178rem 0.178rem 0;
  -webkit-border-radius: 0 0.178rem 0.178rem 0;
  -moz-border-radius: 0 0.178rem 0.178rem 0;
  -ms-border-radius: 0 0.178rem 0.178rem 0;
  -o-border-radius: 0 0.178rem 0.178rem 0;
}
.sidebar_link.active::before {
  background-color: #46c064;
}
.sidebar_link svg {
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 1.5rem;
  margin-right: 0.75rem;
}
.sidebar_link.active {
  color: #46c064;
}
.sidebar_link.active svg path {
  fill: #46c064;
}
.sidebar_link:hover {
  color: #292d32 !important;
}
.sidebar_link.active:hover {
  color: #46c064 !important;
}
.sidebar_footer {
  background-color: #e58344;
  color: #fff;
}
.sidebar_footer .card-body {
  padding: 1.563rem;
}
.pro_svg {
  width: 2.875rem;
  height: 2.875rem;
  flex: 0 0 2.875rem;
  margin-right: 0.625rem;
}
.uparrow_20 {
  width: 1.25rem;
  height: 1.25rem;
  flex: 0 0 1.25rem;
}
/* Sidebar Nav Menu End */

/* Device Header Start */
@media (max-width: 991.98px) {
  .device-header {
    padding: 1rem;
    z-index: 999;
  }
}
/* Device Header End */

/* Pages Common CSS Start */
.fs-14 {
  font-size: 0.875rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-42 {
  font-size: 2.625rem;
}
.lh-normal {
  line-height: normal;
}
.back_link {
  color: #ee853a;
}
.back_link svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
.card-body.p-30 {
  padding: 1.875rem;
}

.kriya_table_style .table-dark {
  --bs-table-bg: #46c064;
  --bs-table-border-color: #46c064;
}
.kriya_table_style thead th {
  font-size: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.875rem;
  font-weight: 700 !important;
  font-family: "Nunito Bold";
}
.kriya_table_style tbody td {
  color: #292d32;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.875rem;
}
.kriya_table_style tbody tr:last-child td {
  border-bottom: 0;
}
.table_action_icon svg[width="36"][height="36"] {
  width: 2.25rem;
  height: 2.25rem;
}
.table_action_icon {
  text-decoration: none;
  margin-right: 0.75rem;
}

.page-search {
  width: 18.75rem;
  max-width: 100%;
}
.page-search .form-control::-moz-placeholder {
  color: #292d32;
  opacity: 1;
}
.page-search .form-control::placeholder {
  color: #292d32;
  opacity: 1;
}
.page-search .form-control {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g clip-path="url(%23clip0_10_455)"><path d="M17.8128 16.8991L14.1489 13.2352C15.3445 11.8339 16.0709 10.0147 16.0709 8.03494C16.0709 3.60608 12.4648 0 8.03592 0C3.60706 0 0.000976562 3.60608 0.000976562 8.03494C0.000976562 12.4638 3.60706 16.0699 8.03592 16.0699C10.0222 16.0699 11.8348 15.3435 13.2361 14.1479L16.9001 17.8119C17.5107 18.4225 18.4042 17.4969 17.8064 16.9055L17.8128 16.8991ZM1.28657 8.03494C1.28657 4.31316 4.31413 1.28559 8.03592 1.28559C11.7577 1.28559 14.7853 4.31316 14.7853 8.03494C14.7853 11.7567 11.7577 14.7843 8.03592 14.7843C4.31413 14.7843 1.28657 11.7567 1.28657 8.03494Z" fill="%2346C064"/></g><defs><clipPath id="clip0_10_455"><rect width="18" height="18" fill="white"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  background-color: #fff;
  border-color: #fff;
  padding-left: 2.75rem;
  font-size: 1.125rem;
  background-position: 1rem;
}

@media (max-width: 767.98px) {
  h2.fs-42 {
    font-size: 1.625rem;
  }
  .btn-lg {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-font-size: 0.875rem;
  }
}
@media (max-width: 575.98px) {
  h2.fs-42 {
    font-size: 1.5rem;
  }
  .card-body.p-30 {
    padding: 1rem;
  }
}
/* Pages Common CSS End */

/* Dashboard page CSS Start */
.dashboard_card,
.dashboard_card * {
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}
.dashboard_card .card-body {
  padding: 1.125rem 1.25rem;
}
.dash_svg {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
  margin-right: 0.625rem;
  margin-top: -0.125rem;
}
.dash_card_title {
  color: #132f21;
}
.svg_50 {
  width: 3.125rem;
  height: 3.125rem;
}

.ee_dash_card .dash_card_title {
  color: #809fb3;
}
.ee_dash_card:hover {
  background-color: #809fb3;
}

.mm_dash_card .dash_card_title {
  color: #9bd0b6;
}
.mm_dash_card:hover {
  background-color: #9bd0b6;
}

.chant_dash_card .dash_card_title {
  color: #d68d96;
}
.chant_dash_card:hover {
  background-color: #d68d96;
}

.hs_dash_card .dash_card_title {
  color: #986d9a;
}
.hs_dash_card:hover {
  background-color: #986d9a;
}

.aum_dash_card .dash_card_title {
  color: #d4caa8;
}
.aum_dash_card:hover {
  background-color: #d4caa8;
}

.kriya_dash_card .dash_card_title {
  color: #b0b38e;
}
.kriya_dash_card:hover {
  background-color: #b0b38e;
}

.dc_common:hover svg path {
  fill: #fff;
}
.dc_common:hover svg.svg_50 path {
  fill: rgba(255, 255, 255, 0.5);
}
.dc_common:hover * {
  color: #fff;
}
@media (max-width: 767.98px) {
  h3.fs-42 {
    font-size: 1.875rem;
  }
  .dashboard_card .card-body {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .dash_svg {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    margin-right: 0.5rem;
  }
  .dash_card_title.fs-18 {
    font-size: 0.875rem;
  }
  .svg_50 {
    width: 2.125rem;
    height: 2.125rem;
  }
}
/* Dashboard page CSS End */

/* User Details Page CSS Start */
.svg_16 {
  width: 1rem;
  height: 1rem;
}
.idproof_card::before {
  content: "";
  display: block;
  padding-top: 62.75%;
}
/* User Details Page CSS End */

/* Retrospection Page CSS Start */
.routine_tab {
  padding: 0.75rem 1.5rem;
  display: inline-block;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}
.routine_tab.active {
  background-color: #ee853a;
  color: #fff !important;
  font-family: "Nunito Bold";
}
.retrospection_card .card-body {
  padding: 1.25rem 0.5rem 0.75rem;
}
.retrospection_card .dash_svg {
  margin-right: 0;
  width: 3.75rem;
  height: 3.75rem;
  flex: 0 0 3.75rem;
  margin-bottom: 0.75rem;
}
.retrospection_card .border p {
  color: #939598;
}

/* .ee_data .border {
  border-color: #809fb3 !important;
} */
.ee_data .border h3 {
  color: #809fb3;
}
.mm_data .border {
  border-color: #9bd0b6 !important;
}
.mm_data .border h3 {
  color: #9bd0b6;
}
.chant_data .border {
  border-color: #d68d96 !important;
}
.chant_data .border h3 {
  color: #d68d96;
}
.hs_data .border {
  border-color: #986d9a !important;
}
.hs_data .border h3 {
  color: #986d9a;
}
.aum_data .border {
  border-color: #d4caa8 !important;
}
.aum_data .border h3 {
  color: #d4caa8;
}
.kriya_data .border {
  border-color: #b0b38e !important;
}
.kriya_data .border h3 {
  color: #b0b38e;
}
@media (max-width: 767.98px) {
  .routine_tab {
    font-size: 0.875rem !important;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 575.98px) {
  .retrospection_card .dash_svg {
    width: 2.75rem;
    height: 2.75rem;
    flex: 0 0 2.75rem;
  }
  .retrospection_card .card-body {
    padding: 1rem 0.5rem 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .dash_card_title.fs-22 {
    font-size: 1rem;
  }
  .retrospection_card .fs-4 {
    font-size: 1rem !important;
  }
  .retrospection_card .fs-4 + .fs-6 {
    font-size: 0.75rem !important;
  }
}
/* Retrospection Page CSS End */

/* Energy Exercise Page CSS Start */
.svg_52 {
  width: 3.25rem;
  height: 3.25rem;
  flex: 0 0 3.25rem;
}
.fs-34 {
  font-size: 2.125rem;
}
/* Energy Exercise Page CSS End */

/* Profile Page CSS Start */
.profile_upload_box {
  width: 7.5rem;
  height: 7.5rem;
  background-color: #e1fcdb;
}
.profile_upload_box svg[width="54"][height="54"] {
  width: 3.375rem;
  height: 3.375rem;
}
.profile_upload_box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
}
.uploadIcon {
  opacity: 0;
  z-index: 1;
  transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
}
.profile_upload_box:hover::after,
.profile_upload_box:hover .uploadIcon {
  opacity: 1;
}
/* Profile Page CSS End */

/******* Main Pages CSS End *******/
