/* Admin Index */

/* Navigation Panel  */

/* InfoCard */
.AdminIndex .InfoCard > div:hover .labelContainer {
	color: #fff !important;
}

.AdminIndex .InfoCard > div:hover .infoCardData {
	color: #fff !important;
}

/* Dashboard */
.AdminIndex .Dashboard .ant-picker-filled {
	background: rgba(255, 255, 255, 1) !important;
}

.AdminIndex .Dashboard .ant-picker-filled:hover {
	background: rgba(255, 255, 255, 1) !important;
}

/* Users */
.AdminIndex .ant-picker-filled {
	background: rgba(255, 255, 255, 1) !important;
}

.AdminIndex .antTable {
	border-radius: 0.625rem !important;
}

/* .antTable .ant-table-thead > tr {
  background-color: #46c064 !important;
} */

.ant-table-wrapper .ant-table {
	border-radius: 0.625rem !important;
}

.AdminIndex .antTable .ant-table-thead > tr {
	background-color: #46c064 !important;
}

.AdminIndex .antTable .ant-table-thead > tr > th {
	color: white;
	font-family: "Nunito" !important;
	font-weight: 700 !important;
	font-size: 1rem !important;
	background-color: #46c064 !important;
	/* text-align: center !important; */
}

.AdminIndex .antTable .ant-table-tbody > tr > td {
	font-family: "Nunito" !important;
	font-weight: 400 !important;
	font-size: 1.125rem !important;
	color: var(--blackLt) !important;
	/* text-align: center !important; */
}

.AdminIndex .antTable .ant-pagination .ant-pagination-item-active {
	border: 0 !important;
	background-color: #e58344 !important;
	color: #fff !important;
}

.modelMaxHeight .ant-modal-content {
	max-height: min(80vh, 700px);
	/* max-height: 500px; */
	overflow-y: scroll;
	padding: 20px 20px !important;
	padding-right: 0px !important;

	/* z-index: 1038 !important; */
}

.modelMaxHeight .ant-modal-content::-webkit-scrollbar {
	border-radius: 0px 6px 6px 0px !important;
	background-color: #fff !important;
}

.ant-modal .ant-modal-close {
	inset-inline-end: 0 !important;
	top: 1rem !important;
}

.ant-modal .ant-modal-close:hover {
	background-color: #fff;
}

.AdminIndex .list {
	width: 12.5rem !important;
	background-color: #e1fcdb !important;
}

.AdminIndex .headerAccount {
	cursor: pointer !important;
}

/* User Details View */
.AdminIndex .UserDetails .idProof {
	border: 1px solid rgb(106, 102, 102) !important;
	background-color: white !important;
	/* max-height: 300px;
  overflow: auto !important; */
}

.AdminIndex .headerAccount ul {
	position: absolute;
	left: 5px;
	bottom: calc(100% + 0.5rem);
	box-shadow: 0px 6px 14px rgba(52, 58, 63, 0.08);
	border-radius: 1rem;
	min-width: max-content;
	display: none;
}

.AdminIndex .headerAccount ul li a {
	line-height: 40px;
}

.AdminIndex .headerAccount ul li a {
	color: rgba(39, 39, 39, 1);
}

.AdminIndex .headerAccount.is-active ul {
	display: block;
}

.AdminIndex .logo {
	width: 2.438rem !important;
	height: 2.25rem !important;
}

/* RetroSpection View */
.AdminIndex .RetroSpection .ant-picker-filled {
	background: rgba(255, 255, 255, 1) !important;
}

.AdminIndex .radioWithLabelActive input {
	width: 0;
	height: 0;
	opacity: 0;
	min-width: unset;
	min-height: unset;
}

.AdminIndex .radioWithLabelActive label:has(input:checked) {
	background: var(--orange) !important;
	color: white !important;
}

/* Exercise Details View */
.AdminIndex .ExerciseDetails .ant-picker-filled {
	background: rgba(255, 255, 255, 1) !important;
}

/* Table */
.ant-table-wrapper .ant-table-thead > tr > th {
	padding: 0.5rem 1rem !important;
}

.ant-table-wrapper .ant-table-tbody .ant-table-cell {
	padding: 0.8rem 1rem !important;
}

.ant-table-content::-webkit-scrollbar {
	display: none !important;
}

.ant-table-tbody tr:last-child td {
	border-bottom: none;
}

/*RangePicker */

.DateRangePicker {
	font-family: "Nunito", sans-serif !important;
	font-size: 1.125rem !important;
	font-weight: 400 !important;
	color: #000000 !important;
}

/* Header Modal */
.headerModel .header {
	min-height: 90px;
	border-bottom: 1px solid #d9d9d9;
}

.headerModel .ant-modal-content {
	border-radius: 0;
	padding: 0;
	background-color: var(--light);
}

.headerModel .mobileCircle {
	width: 40px;
	height: 40px;
	min-width: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
	background: rgba(14, 52, 75, 0.1);
}

.headerModel .customerMenubar a.active .mobileCircle {
	background: var(--orange);
}

.headerModel .customerMenubar a.active .mobileCircle svg [fill="#343A3F"] {
	fill: #fff;
}

.headerModel .customerMenubar a.active .mobileCircle svg [stroke="#343A3F"] {
	stroke: #fff;
}

.headerModel .ant-modal-content .customerMenubar a {
	display: flex;
	align-items: center;
}

.headerModel .ant-modal-body {
	/* background-color: #e9f8e5 !important; */
	background-color: white !important;
}

/* Profile View */
.uploadProfile .antdSelect.item-selected .ant-select-arrow {
	display: block;
}

.uploadProfile .customerProfileAvatarBox {
	display: inline-flex;
	flex-shrink: 0;
	position: relative;
	vertical-align: middle;
}

.uploadProfile .customerProfileAvatarImage {
	align-items: center;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	font-size: 1.25rem;
	height: 12.5rem;
	justify-content: center;
	line-height: 1;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	width: 12.5rem;
}

.uploadProfile .customerProfileAvatarImage img {
	color: transparent;
	height: 100%;
	text-align: center;
	text-indent: 10000px;
	width: 100%;
}

.uploadProfile .customerProfileAvatarImage.is-active img {
	border: 0.063rem solid var(--primary-opacity);
	border-radius: 50%;
	object-fit: cover;
}

/* Antd Table */
.ant-table-row > .ant-table-cell-row-hover {
	background: none !important;
}

.ant-select-selector {
	border-radius: 1.563rem !important;
}

.uploadProfile .customerProfileAvatarChange {
	align-content: center;
	align-items: center;
	background-color: #fff;
	border-radius: 50%;
	bottom: 14%;
	box-shadow: 0 0.375rem 0.313rem 0 rgba(52, 58, 63, 0.09);
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 0.75rem;
	font-weight: 500;
	height: 3.75rem;
	width: 3.75rem;
	justify-content: center;
	line-height: 1;
	min-width: 2.625rem;
	position: absolute;
	-webkit-transform: scale(1) translate(50%, 50%);
	transform: scale(1) translate(50%, 50%);
	-webkit-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
	transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	z-index: 1;
	right: 12%;
	bottom: 4.25rem;
}

.uploadProfile .customerProfileUpload .ant-upload-list {
	display: none;
}

@media screen and (max-width: 768px) {
	.uploadProfile .customerProfileAvatarImage {
		width: 10rem;
		height: 10rem;
	}

	.uploadProfile .customerProfileAvatarChange {
		height: 2.625rem;
		width: 2.625rem;
		right: 14%;
		bottom: 3 0.25rem;
	}
}

@media screen and (max-width: 991px) {
	.AdminIndex .RetroSpection .radioWithLabelActive {
		overflow-x: scroll;
		display: flex;
	}

	.AdminIndex .RetroSpection .radioWithLabelActive::-webkit-scrollbar {
		display: none !important;
	}
}

@media screen and (min-width: 991px) and (max-width: 1399px) {
	.AdminIndex .RetroSpection .radioWithLabelActive {
		overflow-x: scroll;
		display: flex;
	}

	.AdminIndex .RetroSpection .radioWithLabelActive::-webkit-scrollbar {
		display: none;
	}
}
/* bulk add */
.antdDragger {
	align-items: center;
	background-color: #f6f6f6;
	display: flex;
}

.antdDragger,
.antdDragger * {
	font-family: "Nunito", sans-serif;
}

.antdDragger.ant-upload-wrapper.docUpload {
	flex-direction: column !important;
	position: relative;
}

.antdDragger.ant-upload-wrapper .ant-upload-drag {
	background-color: #f6f6f6;
	border: 1px dashed var(--success);
	border-radius: 8px;
}

.antdDragger .ant-upload .ant-upload-btn {
	min-height: 150px;
}

.BulkImportBondsMan .ant-modal-body .fw-semibold,
.BulkImportBondsMan .ant-modal-body .fw-bold {
	font-family: "Nunito", sans-serif;
}

.BulkImportBondsMan .antdModalTitle {
	font-size: 1.75rem;
	font-weight: 700;
	line-height: 2.383rem;
	margin: 0 auto;
	max-width: 85%;
	text-align: center;
	font-family: "Nunito", sans-serif;
}

.BulkImportBondsMan .ant-modal-close {
	right: 12px !important;
}

.BulkImportBondsMan .ant-modal-body button.txt-primary {
	color: #0047ab;
	text-decoration: underline;
}

/* RadioButtonSelectAnyOne */
.RadioButtonSelectAnyOne input[type="radio"] {
	width: 0;
	height: 0;
	line-height: 0;
	visibility: hidden;
}

.BulkImportBondsMan .RadioButtonSelectAnyOne {
	background-color: var(--success) !important;
	padding: 5px 10px;
	height: 2.75rem;
	border-radius: 4px;
	color: #fff !important;
}

.BulkImportBondsMan .RadioButtonSelectAnyOne.isActive {
	background-color: #0b2b26 !important;
}

.BulkImportBondsMan .RadioButtonSelectAnyOne {
}

.BulkImportBondsMan .RadioButtonSelectAnyOne.zeroAssign {
	display: none !important;
}

.uploadPreviewBorder {
	background-color: #f6f6f6;
	border: 1px dashed #f16d3d;
	border-radius: 8px;
}
.txt-error {
	color: #f16d3d !important;
	color: var(--error) !important;
}
/*  */

/* .gap-2 {
	gap: 0.5rem !important;
}
.mt-1 {
	margin-top: 0.25rem !important;
}

.border-1 {
	border-style: solid;
	border-width: 0.063rem;
}
.border-s {
	border-radius: 0.313rem !important;
}
.cursor-pointer {
	cursor: pointer;
}
.txt-error {
	color: #f16d3d !important;
	color: var(--error) !important;
} */
