:root {
  --primary: #3961e5;
  --error: #fc3f3c;
  --secondary: #c3c7d3;
}

/* SearchBar */
.searchBar {
  height: max-content;
}

.searchBar .searchIcon,
.searchBar .clearSearch {
  /* height: 1.6rem; */
  position: absolute;
  left: 0.925rem;
  top: 50%;
  /* bottom: 50%; */
  -webkit-transform: translate(-25%, -50%);
  transform: translate(-25%, -50%);
}

.searchBar input:placeholder-shown {
  text-overflow: ellipsis;
}

.searchBar .searchInput {
  padding-right: 1.875rem;
  background: #fff;
}

.ToggleButton {
  position: absolute;
  right: 19.2px;
  top: 0.5rem;
  text-align: end;
  width: auto;
  padding: 0;
  margin: 0;
}

.ToggleButton button {
  background-color: #46c064;
  padding: 0.5rem 1.2rem;
  border-radius: 0.375rem;
}

.ToggleButton .ToggleBGlist {
  background: #e3ecc6;
  border-radius: 12px;
  text-align: start;
  min-width: 7rem;
  display: none;
  transition: all 0.25s ease;
  color: var(--light);
}

.ToggleButton .ToggleBGlist ul li {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  line-height: 2rem;
  list-style: none;
  cursor: pointer;
}

.ToggleButton .ToggleBGlist.is-active {
  display: block;
  z-index: 9;
  position: relative;
}
