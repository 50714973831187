html,
body,
#root,
.ant-app {
	font-family: "Nunito", sans-serif;
}

* {
	border: none;
	outline: none;
}

:root {
	--primary: #3961e5;
	--primary-bg: #ebf0f6;
	--lightGray: #f2f2f2;
	--green: #132f21;
	--white: #ffffff;
	--sky: #809fb3;
	--parrot: #9bd0b6;
	--peach: #d68d96;
	--pink: #986d9a;
	--lightDesert: #d4caa8;
	--desert: #b0b38e;
	--orange: #ee853a;
	--black: #000000;
	--blackSm: #1b1b1b;
	--blackLt: #292d32;
	--lightPink: #e6a3c9;
	--lightPeach: #f2c6a1;
	--lightPurple: #8d9df5;
	--darkSky: #446c8f;
	--skySecondary: #1b96b1;
	--success: #46c064;
	--error: red;
}

.primary-bg {
	background-color: var(--primary-bg) !important;
}

.c-success {
	background-color: var(--success) !important;
}
.c-error {
	background-color: var(--error) !important;
}
.p {
	margin-bottom: 0;
}

/* Font Family */
.ff {
	font-family: "Nunito" !important;
}

.ff_rg {
	font-weight: 400 !important;
}

.ff_md,
.fw-medium {
	font-weight: 600 !important;
}

.ff_bd {
	font-weight: 700 !important;
}

/* Font Size */
.F12 {
	font-size: 12px !important;
}

.f10 {
	font-size: 0.625rem;
}

.f12 {
	font-size: 0.75rem;
}

.f13 {
	font-size: 0.813rem;
}

.f14 {
	font-size: 0.875rem;
}

.f16 {
	font-size: 1rem !important;
}

.f18 {
	font-size: 1.125rem !important;
}

.f20 {
	font-size: 1.25rem !important;
}

.f22 {
	font-size: 1.375rem;
}

.f24 {
	font-size: 1.5rem;
}

.f28 {
	font-size: 1.75rem;
}

.f30 {
	font-size: 1.875rem;
}

.f32 {
	font-size: 2rem;
}

.f40 {
	font-size: 2.5rem;
}

/* Opacity */
.opacity-0 {
	opacity: 0%;
}

.opacity-25 {
	opacity: 25%;
}

.opacity-50 {
	opacity: 50%;
}

.opacity-75 {
	opacity: 75%;
}

.opacity-100 {
	opacity: 100%;
}

/* Input Field */
.input-box-shadow {
	box-shadow: 0 0.125rem 0.375rem 0 #0187cf33;
}

.focus-border:focus {
	border: 0.063rem solid var(--primary);
	box-shadow: none;
}

.rounded-8 {
	border-radius: 0.5rem;
}

.replyCss {
	border-radius: 10px 10px 10px 0;
}

/* Border Radius */

.border-m {
	border-radius: 2rem !important;
}

/* Background Color */

/* Text color */

.txt-green {
	color: var(--green) !important;
}

.txt-white {
	color: var(--white) !important;
}

.txt-sky {
	color: var(--sky) !important;
}
.txt-parrot {
	color: var(--parrot) !important;
}

.txt-peach {
	color: var(--peach) !important;
}

.txt-pink {
	color: var(--pink) !important;
}

.txt-lightDesert {
	color: var(--lightDesert) !important;
}

.txt-desert {
	color: var(--desert) !important;
}

.txt-orange {
	color: var(--orange) !important;
}

.txt-black {
	color: var(--black) !important;
}

.txt-blackSm {
	color: var(--blackSm) !important;
}
.txt-lightPeach {
	color: var(--lightPeach) !important;
}
.txt-lightPink {
	color: var(--lightPink) !important;
}
.txt-lightPurple {
	color: var(--lightPurple) !important;
}
.txt-darkSky {
	color: var(--darkSky) !important;
}
.txt-skySecondary {
	color: var(--skySecondary) !important;
}
/* Background colors */
.c-green {
	background-color: var(--green) !important;
}

.c-orange {
	background-color: var(--orange) !important;
}

.c-sky {
	background-color: var(--sky) !important;
}

.c-parrot {
	background-color: var(--parrot) !important;
}

.c-skyHover:hover {
	background-color: var(--sky) !important;
}

.c-parrotHover:hover {
	background-color: var(--parrot) !important;
}

.c-peachHover:hover {
	background-color: var(--peach) !important;
}

.c-pinkHover:hover {
	background-color: var(--pink) !important;
}

.c-lightDesertHover:hover {
	background-color: var(--lightDesert) !important;
}
.c-desertHover:hover {
	background-color: var(--desert) !important;
}
.c-blackHover:hover {
	background-color: var(--black) !important;
}
.c-lightPinkHover:hover {
	background-color: var(--lightPink) !important;
}
.c-lightPeachHover:hover {
	background-color: var(--lightPeach) !important;
}
.c-lightPurpleHover:hover {
	background-color: var(--lightPurple) !important;
}
.c-darkSkyHover:hover {
	background-color: var(--darkSky) !important;
}
.c-skySecondaryHover:hover {
	background-color: var(--skySecondary) !important;
}

::placeholder {
	font-family: "Nunito", Arial, Helvetica, sans-serif !important;
	font-weight: 400 !important;
	color: #939598 !important;
	font-size: 1.1rem !important;
}

.h50 {
	height: 3.125rem !important;
}

.w143 {
	width: 8.938rem !important;
}

.w96 {
	width: 6rem !important;
}

.min-fit-content {
	min-width: fit-content;
}

/* Borders */

.border-25 {
	border-radius: 1.563rem !important;
}

.border-10 {
	border-radius: 0.625rem !important;
}

.border-sky {
	border-color: var(--sky) !important;
}

.border-parrot {
	border-color: var(--parrot) !important;
}
.border-peach {
	border-color: var(--peach) !important;
}

.border-pink {
	border-color: var(--pink) !important;
}

.border-black {
	border-color: var(--black) !important;
}

.border-lightDesert {
	border-color: var(--lightDesert) !important;
}
.border-desert {
	border-color: var(--desert) !important;
}
.border-lightPeach {
	border-color: var(--lightPeach) !important;
}

.border-lightPink {
	border-color: var(--lightPink) !important;
}
.border-lightPurple {
	border-color: var(--lightPurple) !important;
}
.border-darkSky {
	border-color: var(--darkSky) !important;
}
.border-skySecondary {
	border-color: var(--skySecondary) !important;
}
.smallContainer {
	width: 1.875rem;
	height: 2.5rem;
	background-color: #f2f2f2;
}

.cursorP {
	cursor: pointer !important;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
}

/* Device Profile  */
.headerAccount2 {
	cursor: pointer !important;
}
.headerAccount2 ul {
	position: absolute;
	right: -5px;
	top: calc(100% + 0.2rem);
	box-shadow: 0px 6px 14px rgba(52, 58, 63, 0.08);
	border-radius: 1rem;
	min-width: max-content;
	display: none;
}
.headerAccount2 ul li a {
	line-height: 40px;
}
.headerAccount2 ul li a {
	color: rgba(39, 39, 39, 1);
}
.headerAccount2.is-active ul {
	display: block;
}
.loader-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loader {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
